import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import about_image from '../images/about_us_homepage_image.jpg'
import ContactText from '../components/ContactText'
import AnchorLink from 'react-anchor-link-smooth-scroll'

class Homepage extends React.Component{
  render(){
    return(
      <Layout pageType="homepage">
      <SEO title="Business, Digital and Technology Consulting" />
      <section id="_intro" className="hero is-fullheight intro">
        <div className="overlay-image"></div>
        <div className="overlay"></div>
        <div className="hero-body">
          <div className="container">
              <h1 className="title">
                <span>Business, Digital and Technology consulting </span><br />
                to overhaul and transform your business
              </h1>
              <div className="button-area">
                <Link to="/contact-us" className="button green">Contact us to get started</Link>
              </div>
          </div>
        </div>

        <div className="hero-foot">
          <div className="container">
          <AnchorLink offset='100' href="#_services" className="arrow-down"><i></i></AnchorLink>
            <div className="social-box">
              <Link to="/" className="facebook"><i></i></Link>
              <Link to="/" className="linkdin"><i></i></Link>
              <Link to="/" className="twitter"><i></i></Link>
            </div>
          </div>
        </div>
      </section>

      <section id="_services" className="section services">
        <div className="container">
          <div className="columns">
            <Link to="/business-consulting" className="column is-4 business-consulting">
              <span className="icon"></span>
              <h2 className="title">Business Consulting</h2>
              <p>Together we can optimize and transform your business, all the way from the people to the processes.</p>
            </Link>

            <Link to="/technology-services" className="column is-4 technology-services">
              <span className="icon"></span>
              <h2 className="title">Technology Services</h2>
              <p>Together we outline, create and deliver technology solutions to fix problems.</p>
            </Link>

            <Link to="/digital-consulting-services" className="column is-4 digital-solutions">
              <span className="icon"></span>
              <h2 className="title">Digital SOlutions</h2>
              <p>Together we can plan your digital strategy and make your marketing plan a success.</p>
            </Link>
          </div>
        </div>
      </section>

      <section id="_about" className="section about">
        <div className="first-part-back"></div>
        <div className="second-part-back"></div>
        <div className="container">
          <div className="background-overlay"></div>
          <div className="title-area is-mobile">
                <h1 className="title">About us</h1>
                <Link to="/about-us" className="arrow-right"><i></i></Link>
            </div>
          <div className="columns">
            <div className="column is-left image is-6">
              <img src={about_image} />
            </div>
            <div className="title-area is-desktop">
                <h1 className="title">About us</h1>
                <Link to="/about-us" className="arrow-right"><i></i></Link>
            </div>
            <div className="column is-6 content is-right"> 
              <div className="inner">
                <p className="big-text">A quality unmatched consulting experience is what you deserve. We bring it.</p>
                <p className="text">It is our belief that consulting is a group activity, which is why we do not just work 
                for you – we work with you. To create and discover new ideas and unique, fresh perspectives that allow for 
                greater optimization in your processes, elevating your technology, giving you a competitive advantage in 
                an ever-changing digital world.
                 </p>
                 <Link to="/about-us" className="button green">We’re with you. Learn more.</Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="_insights" className="section insights blog-list blue">
        <div className="container">
          <div className="title-area">
            <h1 className="title">Our latest insights</h1>
            <Link to="/blog" className="arrow-right"><i></i></Link>
          </div>
          <div className="columns is-multiline">
            <div className="single column is-4">
                <Link to="/blog/the-importance-of-a-business-transformation-plan-in-implementing-real-change-to-any-business-or-organization" className="inner">
                  <p className="category"><span className="line"></span>BUSINESS</p>
                  <h2 className="title">The importance of a business transformation plan in implementing real change to any business or organization</h2>
              </Link>
            </div>

            <div className="single column is-4">
                <Link to="/blog/the-importance-of-performance-metrics-measurable-data-is-manageable-data" className="inner">
                  <p className="category"><span className="line"></span>TECHNOLOGY</p>
                  <h2 className="title">The importance of performance metrics. Measurable data is manageable data</h2>
                </Link>
            </div>

            <div className="single column is-4">
                <Link to="/blog/misconceptions-and-misinformation-about-machine-learning-consultants-may-affect-your-business" className="inner">
                  <p className="category"><span className="line"></span>TECHNOLOGY</p>
                  <h2 className="title">Misconceptions and Misinformation about Machine Learning consultants may affect your business</h2>
                </Link>
            </div>

            <div className="single column is-4">
                <Link to="/blog/using-rpa-to-improve-workflow-in-the-insurance-industry" className="inner">
                  <p className="category"><span className="line"></span>TECHNOLOGY</p>
                  <h2 className="title">Using RPA to improve workflow in the insurance industry</h2>
                </Link>
            </div>


          </div>
        </div>
      </section>

     <ContactText />
      </Layout>
    )
  }
}

export default Homepage
